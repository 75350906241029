import React from "react";
import { Icon } from "../../ui";
import { NavLink, useNavigate } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { PiEnvelopeSimpleLight } from "react-icons/pi";

const Footer = () => {
  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    console.log(link, "ooooo");
    navigate("/");
  };

  return (
    <>
      <footer className="position-relative ">
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <p
                    onClick={() => {
                      handleLinkClick("home");
                    }}
                  >
                    {/* Logo */}
                    <Icon name="Logo" className="img-fluid mb-3 logo" />
                  </p>

                  <p>
                    Crickstarz11.com is an online fantasy game designed for the
                    fans who like to showcase their cricket knowledge and
                    analytical skills.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Quick Links</h6>
                  <p
                    onClick={() => {
                      handleLinkClick("home");
                    }}
                  >
                    Home
                  </p>
                  <p
                    onClick={() => {
                      handleLinkClick("usp");
                    }}
                  >
                    Features
                  </p>
                  <p
                    onClick={() => {
                      handleLinkClick("whychoose");
                    }}
                  >
                    Why Choose Us?
                  </p>
                  <p>
                    <NavLink to={"/about-us"}>About us</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/contact-us"}>Contact Us</NavLink>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Services</h6>
                  <p>
                    <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/terms-condition"}>Terms & Condition</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/refund-return"}>Refund & Return</NavLink>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <h6>Contact Info</h6>
                  <div className="d-flex align-items-start gap-1 pt-2">
                    <div>
                      <PiEnvelopeSimpleLight size={26} />
                    </div>
                    <p>
                      <a href="mailto:contact@crickstarz11.com">
                        contact@crickstarz11.com
                      </a>
                    </p>
                  </div>

                  <div className="d-flex align-items-start gap-1 pt-2">
                    <div>
                      <CiLocationOn size={26} />
                    </div>

                    <div>
                      <Icon name="Compnay" className="company" />
                      <p>
                        <a href="https://maps.app.goo.gl/sfsYGNWB3QxST6jb6">
                          Shop No.11, Dadu Dayal, CD Block, Kalyanpura,
                          Mansarovar, Jaipur,Rajasthan, India, 302020
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container copy-right">
          <div className="">
            <p>iGaming @2024</p>
          </div>
        </div> */}
      </footer>
      <div className="copy-rightMain">
        <div className="container copy-right">
          <p>Copyright © 2024 Crickstarz11</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
